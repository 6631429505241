import { Page } from '../models/Elements';
import { Company, Plot } from '../models/Entities';
import dateService from '../services/DateService';
import { coreApiAxios } from './CustomAxios';

class CompanyApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, searchText?: string): Promise<Page<Company>> => {
        const response = await coreApiAxios.get<Page<Company>>('/companies', {
            params: { page, size, sortField, sortOrder, searchText, status: 'ENABLED' }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((user) => this.loadDates(user));

        return response.data;
    };

    get = async (companyId: number): Promise<Company> => {
        const response = await coreApiAxios.get<Company>(`/companies/${companyId}`);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (companyId: number, company: Company): Promise<Company> => {
        const response = await coreApiAxios.put<Company>(`/companies/${companyId}`, company);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (companyId: number): Promise<void> => {
        const response = await coreApiAxios.delete<void>(`/companies/${companyId}`);

        return response.data;
    };

    /**
     * Loads the dates of an plot.
     * @param plot - the plot
     */
    private loadDates(plot: Plot) {
        plot.audit!.created = dateService.transformDate(plot.audit!.created)!;
        plot.audit!.updated = dateService.transformDate(plot.audit!.updated)!;
    }
}

const companyApi: CompanyApi = new CompanyApi();
export default companyApi;
