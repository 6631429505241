import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { App, Button, Col, DatePicker, Form, Input, Row, Select, Space, Tabs, TabsProps, Tag, Tooltip, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import plotApi from '../../apis/PlotApi';
import trapApi from '../../apis/TrapApi';
import trapPlotApi from '../../apis/TrapPlotApi';
import ChartAemetHumidityComponent from '../../components/ChartAemetHumidityComponent/ChartAemetHumidityComponent';
import ChartAemetTemperatureComponent from '../../components/ChartAemetTemperatureComponent/ChartAemetTemperatureComponent';
import ChartInsectsComponent from '../../components/ChartInsectsComponent/ChartInsectsComponent';
import ConstantLabel from '../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import MapComponent from '../../components/MapComponent/MapComponent';
import CustomContext from '../../contexts/CustomContext';
import { CustomAuth, Plot, Trap, TrapPlot } from '../../models/Entities';
import { crops, farmings } from '../../models/Types';
import alertService from '../../services/AlertService';
import TrapImagesComponent from './TrapImagesComponent/TrapImagesComponent';
import TrapImagesViewerComponent from './TrapImagesViewerComponent/TrapImagesViewerComponent';
import TrapPlotChangeModal from './TrapPlotChangeModal/TrapPlotChangeModal';
import styles from './TrapPlotPage.module.scss';

/**
 * Returns the trap plot page.
 * @returns the trap plot page.
 */
const TrapPlotPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const { modal } = App.useApp();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const params = useParams<ParamsType>();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const [plot, setPlot] = useState<Plot>();
    const [modalOpen, setModalOpen] = useState<Boolean>(false);
    const [trapPlot, setTrapPlot] = useState<TrapPlot>();
    const [traps, setTraps] = useState<Trap[]>([]);
    const [plots, setPlots] = useState<Plot[]>([]);
    const [loading, setLoading] = useState<'loading' | 'saving' | 'deleting'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                // load plot
                const plot = await plotApi.get(+params.plotId!);
                setPlot(plot);

                //load available plots
                const plotsPage = await plotApi.list(0, 1000, 'id', true, auth.companyId!);
                const plots = plotsPage.content;
                setPlots(plots);

                // load trap plot
                let trapPlot: TrapPlot;
                if (params.id === 'new') {
                    trapPlot = {
                        plot
                    };
                } else {
                    trapPlot = await trapPlotApi.get(+params.id!);
                }
                setTrapPlot(trapPlot);

                // load available traps
                const trapPlotsPage = await trapPlotApi.list(0, 1000, 'id', true, undefined);
                const trapPlots = trapPlotsPage.content;
                const trapsPage = await trapApi.list(0, 1000, 'uuid', true, auth.companyId!);
                const traps = trapsPage.content.filter((t) => t.id === trapPlot.trap?.id || !trapPlots.some((te) => te.trap!.id === t.id));
                setTraps(traps);

                form.setFieldsValue(trapPlot);
            } catch (error: any) {
                console.log(error);
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth, form, intl, params]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedTrapPlot: TrapPlot = Object.assign({}, trapPlot, values, {
                plot: {
                    id: trapPlot?.plot?.id
                }
            });
            if (updatedTrapPlot.id) {
                updatedTrapPlot = await trapPlotApi.update(updatedTrapPlot);
            } else {
                updatedTrapPlot = await trapPlotApi.create(updatedTrapPlot);
            }
            message.success(intl.formatMessage({ id: 'status.saved' }));
            setTrapPlot(updatedTrapPlot);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'trapPlot.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting', danger: true },
            onOk: async () => {
                try {
                    setLoading('deleting');
                    if (trapPlot && trapPlot.id && trapPlot.plot) {
                        await trapPlotApi.delete(trapPlot.id);
                        message.success(intl.formatMessage({ id: 'status.deleted' }));
                        navigate(`/plots/${trapPlot.plot.id}`);
                    }
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    const showModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    /*** VISUAL ***/

    const trapOptions = traps.map((t) => (
        <Select.Option key={t.id} value={t.id}>
            {t.uuid} - <ConstantLabel prefix="plague.type." value={t.plague} />
        </Select.Option>
    ));

    const cropOptions = crops.map((c) => (
        <Select.Option key={c} value={c}>
            <FormattedMessage id={`crop.type.${c}`} />
        </Select.Option>
    ));
    const farmingOptions = farmings.map((c) => (
        <Select.Option key={c} value={c}>
            <FormattedMessage id={`farming.type.${c}`} />
        </Select.Option>
    ));

    const tabs: TabsProps['items'] = [
        {
            key: 'images',
            label: <FormattedMessage id="trapPlot.images" />,
            children: trapPlot && <TrapImagesViewerComponent trapPlot={trapPlot} />
        },
        {
            key: 'results',
            label: <FormattedMessage id="trapPlot.results" />,
            children: trapPlot && <TrapImagesComponent trapPlot={trapPlot} />
        },
        {
            key: 'stats',
            label: <FormattedMessage id="trapPlot.stats" />,
            children: (
                <Row gutter={[24, 24]}>
                    <Col xs={24} lg={24}>
                        <ChartInsectsComponent trapPlot={trapPlot} />
                    </Col>
                    <Col xs={24} lg={12}>
                        <ChartAemetTemperatureComponent plot={trapPlot?.plot} />
                    </Col>
                    <Col xs={24} lg={12}>
                        <ChartAemetHumidityComponent plot={trapPlot?.plot} />
                    </Col>
                </Row>
            )
        },
        {
            key: 'map',
            label: <FormattedMessage id="trapPlot.map" />,
            children: <MapComponent plot={plot} trapPlot={trapPlot} />
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="trap.title" />}
            menu="traps"
            path={[
                { path: '/plots', name: <FormattedMessage id="plots.title" /> },
                { path: `/plots/${plot?.id}`, name: plot?.name },
                { path: `/plots/${plot?.id}`, name: <FormattedMessage id="traps.title" /> },
                {
                    path: `/plots/${plot?.id}/traps/${trapPlot && trapPlot.id ? trapPlot.id : params.id}`,
                    name: trapPlot && trapPlot.id && trapPlot.trap && trapPlot.trap.uuid
                }
            ]}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical" className={styles.container}>
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={8}>
                        <Form.Item
                            label={
                                trapPlot?.status === 'DISABLED' ? (
                                    <Space>
                                        <FormattedMessage id="trapPlot.id" />
                                        <Tooltip title={<FormattedMessage id="trap.status.DISABLED.text" />}>
                                            <Tag color="default">
                                                <ConstantLabel value={trapPlot?.status} prefix="trap.status." />
                                            </Tag>
                                        </Tooltip>
                                    </Space>
                                ) : (
                                    <Space>
                                        <FormattedMessage id="trapPlot.id" />
                                        <Tag color="blue">
                                            <ConstantLabel value={trapPlot?.status} prefix="trap.status." />
                                        </Tag>
                                    </Space>
                                )
                            }
                            name={['trap', 'id']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select
                                placeholder={<FormattedMessage id="form.select" />}
                                size="large"
                                disabled={(trapPlot && !!trapPlot.id) || trapPlot?.status === 'DISABLED'}
                                className={styles.select}
                            >
                                {trapOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <Form.Item label={<FormattedMessage id="trapPlot.plot" />} name={['plot', 'name']} hidden={!trapPlot || !trapPlot.id}>
                            <Input
                                value={['plot', 'name']}
                                disabled
                                size="large"
                                addonAfter={
                                    <Button type="link" onClick={showModal} className={styles.button}>
                                        <FormattedMessage id="trapPlot.changePlot" />
                                    </Button>
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <Form.Item label={<FormattedMessage id="trapPlot.crop" />} name="crop">
                            <Select size="large" disabled={trapPlot?.status === 'DISABLED'}>
                                {cropOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={8}>
                        <Form.Item label={<FormattedMessage id="trapPlot.farming" />} name="farming">
                            <Select size="large" disabled={trapPlot?.status === 'DISABLED'}>
                                {farmingOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={4}>
                        <Form.Item
                            label={<FormattedMessage id="trapPlot.pheromoneRenewed" />}
                            name="pheromoneRenewed"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <DatePicker className={styles.date} size="large" disabled={trapPlot?.status === 'DISABLED'} />
                        </Form.Item>
                    </Col>
                    <Col lg={12} className={styles.buttons}>
                        <Space>
                            <Link to={`/plots/${plot?.id}`}>
                                <Button size="large" icon={<ArrowLeftOutlined />}></Button>
                            </Link>
                            <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                            {trapPlot && trapPlot.id && <Button danger size="large" onClick={remove} icon={<DeleteOutlined />}></Button>}
                        </Space>
                    </Col>
                </Row>

                {plot && plot.id && trapPlot && trapPlot.id && <Tabs defaultActiveKey="images" className={styles.tabs} size="large" items={tabs} />}
            </Form>
            {modalOpen && trapPlot && trapPlot.id && <TrapPlotChangeModal plots={plots} trapPlot={trapPlot} onCancel={closeModal} />}
        </LayoutComponent>
    );
};
export default TrapPlotPage;

type ParamsType = { plotId: string; id: string };
