import { Dayjs } from 'dayjs';
import { Page } from '../models/Elements';
import { TrapImage, TrapImageStats } from '../models/Entities';
import { StatsDateType } from '../models/Types';
import dateService from '../services/DateService';
import { coreApiAxios } from './CustomAxios';

class TrapImageApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        companyId?: number,
        trapPlotId?: number,
        trapId?: number,
        searchText?: string
    ): Promise<Page<TrapImage>> => {
        const response = await coreApiAxios.get<Page<TrapImage>>('/trap-images', {
            params: { page, size, sortField, sortOrder, companyId, trapPlotId, trapId, searchText, status: 'ENABLED' }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((trapImage) => this.loadDates(trapImage));

        return response.data;
    };

    listStats = async (
        startDate: Dayjs,
        endDate: Dayjs,
        statsDateType: StatsDateType,
        companyId?: number,
        plotId?: number,
        trapPlotId?: number
    ): Promise<TrapImageStats[]> => {
        const response = await coreApiAxios.get<TrapImageStats[]>('/trap-images/stats', {
            params: { startDate: startDate.toJSON(), endDate: endDate.toJSON(), statsDateType, companyId, plotId, trapPlotId }
        });
        response.data.forEach((trapImageStatsByTrap) => this.loadDatesTrapImageStatsByTrap(trapImageStatsByTrap));

        return response.data;
    };

    get = async (trapImageId: number): Promise<TrapImage> => {
        const response = await coreApiAxios.get<TrapImage>(`/trap-images/${trapImageId}`);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (trapImage: TrapImage): Promise<TrapImage> => {
        const response = await coreApiAxios.put<TrapImage>(`/trap-images/${trapImage.id}`, trapImage);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (trapImageId: number): Promise<void> => {
        const response = await coreApiAxios.delete<void>(`/trap-images/${trapImageId}`);

        return response.data;
    };

    /**
     * Loads the dates of a trap.
     * @param trap - the trap
     */
    private loadDates(trapImage: TrapImage) {
        trapImage.date = dateService.transformDate(trapImage.date)!;
        trapImage.audit!.created = dateService.transformDate(trapImage.audit!.created)!;
        trapImage.audit!.updated = dateService.transformDate(trapImage.audit!.updated)!;
        trapImage.pheromoneRenewed = dateService.transformDate(trapImage.pheromoneRenewed)!;
        if (trapImage.aemetMeasure) {
            trapImage.aemetMeasure.measurementDate = dateService.transformDate(trapImage.aemetMeasure.measurementDate)!;
        }
    }

    /**
     * Loads the dates of a trap image stats by trap.
     * @param trapImageStatsByTrap - the trap image stats by trap
     */
    private loadDatesTrapImageStatsByTrap(trapImageStats: TrapImageStats) {
        trapImageStats.date = dateService.transformDate(trapImageStats.date)!;
    }
}

const trapImageApi: TrapImageApi = new TrapImageApi();
export default trapImageApi;
