import { Page } from '../models/Elements';
import { TrapPlot } from '../models/Entities';
import { StatusType } from '../models/Types';
import dateService from '../services/DateService';
import { coreApiAxios } from './CustomAxios';

class TrapPlotApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        plotId?: number,
        hasInsectsCount?: boolean,
        status?: StatusType,
        searchText?: string
    ): Promise<Page<TrapPlot>> => {
        const response = await coreApiAxios.get<Page<TrapPlot>>('/trap-plots', {
            params: { page, size, sortField, sortOrder, plotId, hasInsectsCount, status, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((trap) => this.loadDates(trap));

        return response.data;
    };

    get = async (id: number): Promise<TrapPlot> => {
        const response = await coreApiAxios.get<TrapPlot>(`/trap-plots/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (trapPlot: TrapPlot): Promise<TrapPlot> => {
        const response = await coreApiAxios.post<TrapPlot>(`/trap-plots`, trapPlot);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (trapPlot: TrapPlot): Promise<TrapPlot> => {
        const response = await coreApiAxios.put<TrapPlot>(`/trap-plots/${trapPlot.id}`, trapPlot);
        this.loadDates(response.data);

        return response.data;
    };

    updatePlot = async (trapPlot: TrapPlot): Promise<TrapPlot> => {
        const response = await coreApiAxios.post<TrapPlot>(`/trap-plots/${trapPlot.id}/plot`, trapPlot);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (trapPlotId: number): Promise<void> => {
        const response = await coreApiAxios.delete<void>(`/traps/${trapPlotId}`);

        return response.data;
    };

    /**
     * Loads the dates of a trap plot.
     * @param trap - the trap plot
     */
    private loadDates(trapPlot: TrapPlot) {
        trapPlot.audit!.created = dateService.transformDate(trapPlot.audit!.created)!;
        trapPlot.audit!.updated = dateService.transformDate(trapPlot.audit!.updated)!;
        trapPlot.pheromoneRenewed = dateService.transformDate(trapPlot.pheromoneRenewed)!;
    }
}

const trapPlotApi: TrapPlotApi = new TrapPlotApi();
export default trapPlotApi;
