import { DatePicker } from 'antd';
import { Card, TimeRangePickerProps } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import aemetApi from '../../apis/AemetApi';
import CustomContext from '../../contexts/CustomContext';
import { AemetMeasureStats, CustomAuth, Plot } from '../../models/Entities';
import alertService from '../../services/AlertService';
import dateService from '../../services/DateService';
import styles from './ChartAemetHumidityComponent.module.css';

/**
 * Returns the chart AEMET humidity component.
 * @returns the chart AEMET humidity component
 */
const ChartAemetHumidityComponent: React.FC<Props> = (props) => {
    const { plot } = props;
    const { RangePicker } = DatePicker;

    /*** HOOKS ***/

    const intl = useIntl();
    const [dataset, setDataset] = useState<(string | number | undefined)[][]>([]);
    const [series, setSeries] = useState<string[]>([]);
    const [dimensions, setDimensions] = useState<string[]>([]);
    const [, setLoading] = useState<'loading'>();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(14, 'days'));
    const [endDate, setEndDate] = useState<Dayjs>(dayjs());

    /*** EFFECTS ***/

    useEffect(() => {
        const buildChartData = (aemetMeasureStats: AemetMeasureStats[]): any => {
            const series: string[] = aemetMeasureStats.map((ams) => ams.plot.name!);
            const dimensions: string[] = ['timestamp', ...series];

            const dates: Dayjs[] = aemetMeasureStats
                .flatMap((ams) => ams.aemetMeasures)
                .map((tis) => tis.measurementDate)
                .map((d) => d.startOf('day'))
                .filter((value, index, self) => index === self.findIndex((t) => t.isSame(value, 'day')))
                .sort(dateService.sort);
            const dataset: (string | number | undefined)[][] = dates.map((date) => {
                const values: (number | undefined)[] = aemetMeasureStats.flatMap((ams) =>
                    ams.aemetMeasures
                        .filter((measure) => measure.measurementDate.isSame(date, 'day'))
                        .map((measure) => measure.avgHumidity)
                        .find((avgHumidity) => avgHumidity !== undefined)
                );

                return [date.toISOString(), ...values];
            });

            return { series, dimensions, dataset };
        };
        const init = async () => {
            try {
                setLoading('loading');

                let aemetMeasureStats: AemetMeasureStats[] = [];
                if (auth.companyId && plot && plot.id) {
                    aemetMeasureStats = await aemetApi.listStats(startDate, endDate, 'DAY', auth.companyId, plot.id);
                } else if (auth.companyId && !plot) {
                    aemetMeasureStats = await aemetApi.listStats(startDate, endDate, 'DAY', auth.companyId);
                }
                const { dimensions, series, dataset } = buildChartData(aemetMeasureStats);

                setSeries(series);
                setDimensions(dimensions);
                setDataset(dataset);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, auth.companyId, plot, startDate, endDate]);

    /*** METHODS ***/

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            const startDate = dateStrings[0];
            const endDate = dateStrings[1];

            setStartDate(dayjs(startDate));
            setEndDate(dayjs(endDate));
        }
    };

    /*** VISUAL ***/

    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: <FormattedMessage id={`dashboard.range.7.days`} />, value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: <FormattedMessage id={`dashboard.range.14.days`} />, value: [dayjs().add(-14, 'd'), dayjs()] },
        { label: <FormattedMessage id={`dashboard.range.30.days`} />, value: [dayjs().add(-30, 'd'), dayjs()] },
        { label: <FormattedMessage id={`dashboard.range.90.days`} />, value: [dayjs().add(-90, 'd'), dayjs()] }
    ];

    return (
        <Card
            bordered={false}
            className={styles.card}
            title={<FormattedMessage id="dashboard.chart.humidity.title" />}
            extra={
                <RangePicker
                    presets={rangePresets}
                    size="small"
                    onChange={onRangeChange}
                    style={{ maxWidth: 220 }}
                    defaultValue={[startDate, endDate]}
                    allowClear={false}
                />
            }
        >
            <ReactECharts
                option={{
                    legend: {
                        bottom: 0
                    },
                    grid: {
                        top: 10
                    },
                    dataset: {
                        source: dataset,
                        dimensions
                    },
                    xAxis: { type: 'time' },
                    yAxis: {},
                    series: series.map((s) => ({
                        name: s,
                        type: 'line',
                        connectNulls: true,
                        smooth: true,
                        encode: {
                            x: 'timestamp',
                            y: s
                        }
                    })),
                    tooltip: {
                        trigger: 'axis',
                        valueFormatter: (value: number | undefined) =>
                            value === undefined
                                ? ''
                                : intl.formatNumber(value, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      unit: 'percent',
                                      unitDisplay: 'short',
                                      style: 'unit'
                                  }),
                        axisPointer: {
                            label: {
                                formatter: function (params: any) {
                                    return dayjs(params.value).format('DD/MM/YYYY');
                                }
                            }
                        }
                    }
                }}
            />
        </Card>
    );
};
export default ChartAemetHumidityComponent;

interface Props {
    plot?: Plot;
}
