import { ArrowLeftOutlined, DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { provinces } from 'all-spanish-cities';
import { Province } from 'all-spanish-cities/dist/types';
import { App, Button, Col, Form, Input, InputNumber, Row, Select, Space, Tabs, Tooltip, message } from 'antd';
import { TabsProps } from 'antd/lib';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import aemetApi from '../../apis/AemetApi';
import plotApi from '../../apis/PlotApi';
import ChartAemetHumidityComponent from '../../components/ChartAemetHumidityComponent/ChartAemetHumidityComponent';
import ChartAemetTemperatureComponent from '../../components/ChartAemetTemperatureComponent/ChartAemetTemperatureComponent';
import ChartInsectsComponent from '../../components/ChartInsectsComponent/ChartInsectsComponent';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import MapComponent from '../../components/MapComponent/MapComponent';
import CustomContext from '../../contexts/CustomContext';
import { AemetWeatherStation, CustomAuth, Plot } from '../../models/Entities';
import alertService from '../../services/AlertService';
import stringService from '../../services/StringService';
import styles from './PlotPage.module.scss';
import TrapPlotsComponent from './TrapPlotsComponent/TrapPlotsComponent';

/**
 * Returns the plot page.
 * @returns the plot page.
 */
const PlotPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const { modal } = App.useApp();
    const params = useParams<ParamsType>();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [plot, setPlot] = useState<Plot>();
    const [sortedProvinces, setSortedProvinces] = useState<any[]>([]);
    const [desktop] = useResponsiveLayout();
    const [aemetStations, setAemetStations] = useState<AemetWeatherStation[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');

                // load plot
                const plotId = params.id!;
                let plot: Plot;
                if (plotId === 'new') {
                    plot = {
                        companyId: auth.companyId!
                    };
                } else {
                    plot = await plotApi.get(+plotId);
                }
                const sortedProvinces: Province[] = provinces().sort((a, b) => stringService.sort(a.name, b.name));
                const aemetStationsPage = await aemetApi.list(0, 10000, 'name', true);

                setSortedProvinces(sortedProvinces);
                setAemetStations(aemetStationsPage.content);
                form.setFieldsValue(plot);
                setPlot(plot);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl, params.id, auth.companyId]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedPlot: Plot = Object.assign({}, plot, values);
            if (updatedPlot.id) {
                updatedPlot = await plotApi.update(updatedPlot);
            } else {
                updatedPlot = await plotApi.create(updatedPlot);
            }
            setPlot(updatedPlot);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error) {
            alertService.displayError(error, intl, [{ status: 409, message: 'plot.duplicate' }]);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'plot.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting', danger: true },
            onOk: async () => {
                try {
                    setLoading('deleting');

                    await plotApi.delete(plot?.id!);
                    message.success(intl.formatMessage({ id: 'status.deleted' }));

                    navigate('/plots');
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    /*** VISUAL ***/

    const provincesOptions = sortedProvinces.map((p) => (
        <Select.Option key={p.code} value={p.code}>
            {p.name}
        </Select.Option>
    ));

    const aemetStationOptions = aemetStations.map((s) => (
        <Select.Option key={s.aemetWeatherStationId} value={s.aemetWeatherStationId}>
            {s.name}
        </Select.Option>
    ));

    const tabs: TabsProps['items'] = [
        {
            key: 'map',
            label: <FormattedMessage id="plot.map" />,
            children: <MapComponent plot={plot} />
        },
        {
            key: 'stats',
            label: <FormattedMessage id="plot.stats" />,
            children: (
                <Row gutter={[24, 24]}>
                    <Col xs={24} lg={24}>
                        <ChartInsectsComponent plot={plot} />
                    </Col>
                    <Col xs={24} lg={12}>
                        <ChartAemetTemperatureComponent plot={plot} />
                    </Col>
                    <Col xs={24} lg={12}>
                        <ChartAemetHumidityComponent plot={plot} />
                    </Col>
                </Row>
            )
        },
        {
            key: 'traps',
            label: <FormattedMessage id="plot.traps" />,
            children: <TrapPlotsComponent plotId={plot?.id} />
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="plots.title" />}
            menu="plots"
            path={[
                { path: '/plots', name: <FormattedMessage id="plots.title" /> },
                { path: `/plots/${plot && plot.id ? plot.id : params.id}`, name: plot && plot.id && plot.name }
            ]}
        >
            <div className={styles.container}>
                <Form form={form} onFinish={save} colon={false} layout="vertical">
                    <Row gutter={[24, 24]}>
                        <Col span={24} lg={6}>
                            <Form.Item
                                label={<FormattedMessage id="plot.name" />}
                                name="name"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input size="large" maxLength={200} />
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={9}>
                            <Form.Item label={<FormattedMessage id="plot.province" />} name="province">
                                <Select size="large" showSearch filterOption={stringService.filterOptions}>
                                    {provincesOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={9}>
                            <Form.Item label={<FormattedMessage id="plot.city" />} name="city">
                                <Input size="large" maxLength={200} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Col span={24} lg={6}>
                            <Form.Item label={<FormattedMessage id="plot.area" />} name="area">
                                <InputNumber max={1000} size="large" className={styles.input} />
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={9}>
                            <Form.Item label={<FormattedMessage id="plot.aemetStation" />} name="aemetWeatherStationId">
                                <Select size="large" showSearch filterOption={stringService.filterOptions}>
                                    {aemetStationOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={9} className={styles.buttons}>
                            <Space>
                                <Link to="/plots">
                                    <Button size="large" icon={<ArrowLeftOutlined />}></Button>
                                </Link>
                                <Button
                                    type={plot && plot.id ? 'default' : 'primary'}
                                    htmlType="submit"
                                    size="large"
                                    loading={loading === 'loading'}
                                    icon={<SaveOutlined />}
                                >
                                    <FormattedMessage id="button.save" tagName="span" />
                                </Button>
                                {plot && plot.id && <Button danger size="large" onClick={remove} icon={<DeleteOutlined />}></Button>}
                            </Space>
                        </Col>
                    </Row>
                </Form>

                {plot && plot.id && (
                    <Tabs
                        defaultActiveKey="map"
                        className={styles.tabs}
                        size="large"
                        items={tabs}
                        tabBarExtraContent={
                            <Link to={`/plots/${plot.id}/traps/new`}>
                                <Tooltip title={<FormattedMessage id="traps.new.tooltip" />}>
                                    <Button type="primary" size="large" icon={<PlusOutlined />}>
                                        {desktop && <FormattedMessage id="traps.new" tagName="span" />}
                                    </Button>
                                </Tooltip>
                            </Link>
                        }
                    />
                )}
            </div>
        </LayoutComponent>
    );
};
export default PlotPage;
type ParamsType = { id: string };
