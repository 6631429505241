import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PlotApi from '../../apis/PlotApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomContext from '../../contexts/CustomContext';
import { Page } from '../../models/Elements';
import { AemetWeatherStation, CustomAuth, Plot } from '../../models/Entities';
import alertService from '../../services/AlertService';
import paginationService from '../../services/TableService';
import ProvinceComponent from '../../components/ProvinceComponent/ProvinceComponent';
import aemetApi from '../../apis/AemetApi';

/**
 * Returns the plots page.
 * @returns the plots page.
 */

const PlotsPage: React.FC = () => {
    /***HOOKS */
    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const companyId = auth.companyId as number;
    const [filter, setFilter] = useState<Filter>({});
    const [plotsPage, setPlotsPage] = useState<Page<Plot>>();
    const [aemetStations, setAemetStations] = useState<AemetWeatherStation[]>([]);
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('name');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [loading, setLoading] = useState<'loading'>();

    //list plots
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const responses = await Promise.all([
                    PlotApi.list(page, paginationService.pageSize, sortField, sortOrder, companyId, filter.searchText),
                    aemetApi.list(0, 10000, 'name', true)
                ]);
                const plotPage = responses[0];
                const aemetStationsPage = responses[1];
                setPlotsPage(plotPage);
                setAemetStations(aemetStationsPage.content);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [companyId, intl, page, sortField, sortOrder, filter]);

    /*** METHODS ***/
    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterUsers = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    /*** VISUAL ***/

    const items = plotsPage ? plotsPage.content : [];
    const columns: ColumnsType<Plot> = [
        {
            title: <FormattedMessage id="plot.name" />,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string, plot: Plot) => <Link to={`/plots/${plot.id}`}>{value}</Link>
        },

        {
            title: <FormattedMessage id="plot.city" />,
            dataIndex: 'city',
            key: 'city',
            width: 350,
            sorter: true,
            render: (value: string | undefined, plot: Plot) => <Link to={`/plots/${plot.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="plot.province" />,
            dataIndex: 'province',
            key: 'province',
            width: 250,
            sorter: true,
            render: (value: string | undefined, plot: Plot) => (
                <Link to={`/plots/${plot.id}`}>
                    <ProvinceComponent provinceId={value} />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="plot.area" />,
            dataIndex: 'area',
            key: 'area',
            width: 150,
            align: 'right',
            sorter: true,
            render: (value: string | undefined, plot: Plot) => <Link to={`/plots/${plot.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="plot.aemetStation" />,
            dataIndex: 'aemetWeatherStationId',
            key: 'aemetWeatherStationId',
            width: 250,
            render: (value: string | undefined, plot: Plot) => (
                <Link to={`/plots/${plot.id}`}>{aemetStations.find((s) => s.aemetWeatherStationId === value)?.name}</Link>
            )
        }
    ];

    return (
        <LayoutComponent title={<FormattedMessage id="plots.title" />} menu="plots" path={[{ path: '/plots', name: <FormattedMessage id="plots.title" /> }]}>
            <Form form={form} onFinish={filterUsers} colon={false} layout="vertical" requiredMark={false}>
                <Row>
                    <Col span={20} lg={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'plots.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} lg={6} className="table-buttons">
                        <Link to="/plots/new">
                            <Tooltip title={<FormattedMessage id="plots.new.tooltip" />}>
                                <Button type="primary" size="large" icon={<PlusOutlined />}>
                                    {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                </Button>
                            </Tooltip>
                        </Link>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={paginationService.createPagination(plotsPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default PlotsPage;

interface Filter {
    searchText?: string;
}
