import { Page } from '../models/Elements';
import { Plot } from '../models/Entities';
import dateService from '../services/DateService';
import { coreApiAxios } from './CustomAxios';

class PlotApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, companyId: number, searchText?: string): Promise<Page<Plot>> => {
        const response = await coreApiAxios.get<Page<Plot>>('/plots', {
            params: { page, size, sortField, sortOrder, companyId, searchText, status: 'ENABLED' }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((user) => this.loadDates(user));

        return response.data;
    };

    get = async (plotId: number): Promise<Plot> => {
        const response = await coreApiAxios.get<Plot>(`/plots/${plotId}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (plot: Plot): Promise<Plot> => {
        const response = await coreApiAxios.post<Plot>(`/plots`, plot);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (plot: Plot): Promise<Plot> => {
        const response = await coreApiAxios.put<Plot>(`/plots/${plot.id}`, plot);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (plotId: number): Promise<void> => {
        const response = await coreApiAxios.delete<void>(`/plots/${plotId}`);

        return response.data;
    };

    /**
     * Loads the dates of an plot.
     * @param plot - the plot
     */
    private loadDates(plot: Plot) {
        plot.audit!.created = dateService.transformDate(plot.audit!.created)!;
        plot.audit!.updated = dateService.transformDate(plot.audit!.updated)!;
    }
}

const plotApi: PlotApi = new PlotApi();
export default plotApi;
